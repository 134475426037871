import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@styled-icons/material';
import Carousel from 'nuka-carousel';
import useDimension from '../../../src/hooks/use-dimension';
import Tracking from '../../../src/Tracking';
const appConfig = require('../../../src/data.json');

function Promo({promoZone}) {
  useEffect(() => {
    Tracking.addPromotionImpression(promoZone);
  }, [promoZone]);

  return (
    <Image image={`${promoZone.image}`} height={'300px'}>
      <Label>
        <Button
          target={'_self'}
          href={`/products?zone=${promoZone.id}`}
          onClick={() => {
            Tracking.addPromotionImpressionClick(promoZone);
          }}
          className="hover-reverse-btn"
          type="primary">
          {promoZone.label}
        </Button>
      </Label>
    </Image>
  );
}

export default function PromoZoneBottomSection(props) {
  const {promoZones} = props;
  const {dimension} = useDimension();

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer onClick={previousSlide} aria-label="Prev Banner">
      <KeyboardArrowLeft color="white" style={{width: 35, height: 35}} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer onClick={nextSlide} aria-label="Next Banner">
      <KeyboardArrowRight color="white" style={{width: 35, height: 35}} />
    </IconContainer>
  );

  const getSlideWidth = (inWidth) => {
    if (inWidth <= appConfig.breakpoints.sm) {
      return 1;
    } else if (inWidth <= appConfig.breakpoints.lg) {
      return 2;
    }
    return 3;
  };

  const slidesToShow = getSlideWidth(dimension.innerWidth);

  return (
    <Wrapper style={{overflow: 'auto'}}>
      <Carousel
        width={'100%'}
        height={'100%'}
        renderBottomCenterControls={() => false}
        renderCenterLeftControls={renderLeftIcon}
        renderCenterRightControls={renderRightIcon}
        // slideIndex={slideIndex}
        // afterSlide={slideIndex => this.setState({ slideIndex })}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}>
        {promoZones
          .sort((a, b) => {
            if (a.priority === null) {
              return 1;
            } else if (b.priority === null) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .map((promoZone, idx) => (
            <Promo promoZone={promoZone} key={idx} />
          ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Image = styled.div`
  position: relative;
  cursor: pointer;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  height: ${(props) => props.height || '200px'};

  &:hover {
    opacity: 0.75;
  }
`;

const Label = styled.div`
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);

  border-radius: 3px;
  /* display: flex;
  justify-content: center; */

  & > p {
    color: white;
    margin: 0px;
  }

  & .hover-reverse-btn {
    background-color: ${appConfig.colors.main};
    color: white;
    flex-grow: 0;
    border: 0;
    &:hover {
      color: ${appConfig.colors.main};
      background-color: white;
    }
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: transparent;
  border: 0px;
  width: 40px;
  height: 40px;

  :hover {
    background-color: #0004;
  }

  :focus {
    outline: none;
  }
`;
