import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

const availableProps = {
  layout: 'fixed' | 'constrained' | 'fullWidth',
  placeholder: 'blurred' | 'travedSVG' | 'dominantColor',
  formats: ['auto', 'webp', 'avif'],
  aspectRatio: 1, // pass number or fraction , and width then height will be adjusted
};

export default function StaticImage({
  filename,
  extraStyle = {},
  alt = 'static image',
}) {
  const query = graphql`
    query MyQuery {
      allFile(filter: {extension: {nin: "svg"}}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          extension
          publicURL
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        let image = data.allFile.nodes.find(
          (node) => node.relativePath === filename,
        );
        if (!image.childImageSharp && image.extension === 'svg') {
          return <img src={image.publicURL} alt={alt} style={extraStyle} />;
        }
        return (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={alt}
            style={{width: '100%', ...extraStyle}}
          />
        );
      }}
    />
  );
}
