import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Button} from '../../Widgets';
import {dateUtil} from '../../Utils';
import Image from '../../Components/Image';
const config = require('../../data.json');

const PORTRAIT_RADIO = 271 / 440;
const LANDSCAPE_RADIO = 120 / 180;

export default function Item({record, type = 'portrait', extraStyle = {}}) {
  const Wrapper = type === 'portrait' ? WrapperPortrait : WrapperLandscape;

  return (
    <Link style={extraStyle} to={`/article?id=${record.id}`}>
      <Wrapper>
        <Image
          src={record.image}
          alt={'article ' + record.id}
          style={{objectFit: 'contain'}}
          {...(type === 'portrait'
            ? {
                ratio: PORTRAIT_RADIO,
              }
            : {
                width: 200,
                height: 'auto',
              })}
        />

        <section>
          <h3>{record.title || 'No title'}</h3>
          <div className="abstract">{record.outline || 'No abstract'}</div>

          <div className="misc">
            <div className="date">
              {dateUtil.format(dateUtil.processTimestamp(record.created))}
            </div>
            <div style={{flex: 1}} />
            <Button type="link" style={{margin: 0, padding: 0, height: 'auto'}}>
              繼續閱讀
            </Button>
          </div>
        </section>
      </Wrapper>
    </Link>
  );
}

const WrapperPortrait = styled.div`
  border: 1px solid #e0e0e0;
  min-height: 340px;
  display: flex;
  flex-direction: column;

  & > section {
    flex: 1 0 50%;
    padding: 15px;
    display: flex;
    flex-direction: column;

    & > h3 {
      height: 40px;
      padding: 0;
      margin: 0;
      color: ${config.colors.text};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .abstract {
      height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: ${config.colors.main};
      margin-bottom: 10px;
    }

    & > .misc {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 10px;
      color: ${config.colors.main};
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    margin: 0 0 10px 0;
  }
`;

const WrapperLandscape = styled.div`
  border: 1px solid #e0e0e0;
  border-top: 0;
  padding: 10px;
  display: flex;

  & > section {
    margin-left: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;

    & > h3 {
      padding: 0;
      margin: 10px 0;
      color: ${config.colors.text};
      flex-grow: 0;
    }

    & > .abstract {
      flex: 1 0 40px;
      margin: 0;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: ${config.colors.main};
    }

    & > .misc {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 8px;
      font-size: 12px;
      color: ${config.colors.main};
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    & > section {
      & > h3 {
        margin: 0;
        flex: 1;
      }
      & > p {
        display: none;
      }
      & > .misc {
        border: 0;

        & > [type='link'] {
          display: none;
        }
      }
    }
  }
`;
