import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@styled-icons/material';
const appConfig = require('../../../src/data.json');

function Indicator(props) {
  let {currentSlide, goToSlide, slideCount} = props;
  let indexes = Array.from(Array(slideCount).keys());
  return (
    <IndicatorStyle.Wrapper>
      {indexes.map((index) => (
        <IndicatorStyle.Dot
          key={index}
          selected={index === currentSlide}
          onClick={() => goToSlide(index)}
        />
      ))}
    </IndicatorStyle.Wrapper>
  );
}

const IndicatorStyle = {
  Wrapper: styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
  `,
  Dot: styled.div`
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? appConfig.colors.main : 'white'};
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
    box-shadow: 0px 4px 7px -2px #444;
    margin-left: 12px;
  `,
};

const RATIO = 550 / 1240;

export default function BannerCarousel(props) {
  const {imagesArray} = props;

  const renderLeftIcon = ({previousSlide}) => (
    <IconContainer onClick={previousSlide} aria-label="Prev Banner">
      <KeyboardArrowLeft color="white" style={{width: 35, height: 35}} />
    </IconContainer>
  );

  const renderRightIcon = ({nextSlide}) => (
    <IconContainer onClick={nextSlide} aria-label="Next Banner">
      <KeyboardArrowRight color="white" style={{width: 35, height: 35}} />
    </IconContainer>
  );

  if (imagesArray.length === 0) {
    return (
      <ImageWrapper>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'grey',
          }}
        />
      </ImageWrapper>
    );
  }

  return (
    <Carousel
      width={'100%'}
      height={'100%'}
      renderBottomCenterControls={() => false}
      renderCenterLeftControls={renderLeftIcon}
      renderCenterRightControls={renderRightIcon}
      renderBottomRightControls={(props) => <Indicator {...props} />}
      autoplay={true}
      pauseOnHover={true}
      wrapAround={true}>
      {imagesArray.map((d, i) => (
        <ImageWrapper
          key={'carousel-image' + i}
          onClick={() => {
            if (window && d.link) {
              window.open(d.link, '_blank');
            }
          }}>
          <img src={`${d.image}`} alt="not found" />
        </ImageWrapper>
      ))}
    </Carousel>
  );
}

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: transparent;
  border: 0px;
  width: 40px;
  height: 40px;

  :hover {
    background-color: #0004;
  }

  :focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${RATIO * 100}%;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;
